import messages from "../locales/messages";

const profile = {
  firstName: "Elton",
  lastName: "Lucien",
  jobTitle: messages.softwareEngineer,
  bio: messages.bio,
  aboutMeMessage: messages.aboutMeMessage,
  email: "contact@eltonlucien.com",
  phone: "7864064185",
  location: "Miami, FL",
  img: "https://avatars.githubusercontent.com/u/61792119?v=4",
  links: {
    github: "https://github.com/Elton360",
    linkedin: "https://linkedin.com/in/elton-lucien-b739348b",
    spotify: "https://open.spotify.com/user/ba8p1wtfbr4nlg65ardpmyn8a",
  },
  experiences: [
    {
      name: "Kaseya (Datto)",
      title: messages.softwareEngineer,
      from: "2022",
      tags: [
        "React",
        "TypeScript",
        "AntD",
        "Node",
        "Express",
        "GraphQL",
        "Java",
        "Spring Boot",
      ],
      text: messages.experience_1,
    },
    {
      name: "Freelance",
      title: messages.webDeveloper,
      from: "2020",
      to: "2022",
      text: messages.experience_2,
      tags: [
        "HTML",
        "CSS",
        "JavaScript",
        "React",
        "TypeScript",
        "Node",
        "Express",
        "AntD",
        "SCSS",
        "Firebase",
        "SQL",
        "MongoDB",
      ],
    },
  ],
  projects: [
    {
      imageSrc: "work/elton_2.png",
      title: messages.portfolio,
      github: "https://github.com/Elton360/portfolio_site_v2",
      description: messages.work_1,
      tags: ["HTML", "CSS", "JavaScript", "React"],
      position: 1,
    },
    {
      imageSrc: "work/ley.png",
      title: messages.carRental,
      link: "https://leyrental.com",
      isWebsite: true,
      description: messages.work_2,
      tags: ["HTML", "CSS", "JavaScript", "Next", "Firebase"],
      position: 5,
    },
    {
      imageSrc: "work/clubs.png",
      title: messages.ecommerce,
      link: "https://clubstees.com",
      isWebsite: true,
      description: messages.work_3,
      tags: ["HTML", "CSS", "JavaScript", "Next", "Firebase"],
      position: 2,
    },
    {
      imageSrc: "work/unique.png",
      title: messages.restaurant,
      link: "https://uniquecaribbeanrestaurant.com",
      isWebsite: true,
      description: messages.work_4,
      tags: ["HTML", "CSS", "JavaScript", "React", "Firebase"],
      position: 4,
    },
    {
      imageSrc: "work/lausim.png",
      title: messages.realEstate,
      link: "https://lausim.com",
      isWebsite: true,
      description: messages.work_5,
      tags: ["HTML", "CSS", "JavaScript", "React"],
      position: 6,
    },
    {
      imageSrc: "work/bethel.png",
      title: messages.community,
      link: "https://eglisebethel.org",
      isWebsite: true,
      description: messages.work_6,
      tags: ["HTML", "CSS", "JavaScript"],
      position: 7,
    },
    {
      imageSrc: "work/elton_1.png",
      title: messages.portfolio,
      isWebsite: true,
      github: "https://github.com/Elton360/portfolio_site_v1",
      description: messages.work_7,
      tags: ["HTML", "CSS", "JavaScript"],
      position: 8,
    },
    {
      imageSrc: "work/dasmain.png",
      title: messages.music,
      link: "https://yeaboiii.com",
      isWebsite: true,
      description: messages.work_8,
      tags: ["HTML", "CSS", "JavaScript"],
      position: 9,
    },
    {
      imageSrc: "work/oware.png",
      title: messages.game,
      github: "https://github.com/Elton360/Oware",
      description: messages.work_9,
      tags: ["HTML", "CSS", "JavaScript"],
      position: 3,
    },
  ],
};

export default profile;
