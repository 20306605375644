import { defineMessages } from "react-intl";

const messages = defineMessages({
  aboutMe: {
    id: "aboutMe",
    defaultMessage: "About Me",
  },
  skills: {
    id: "skills",
    defaultMessage: "Skills",
  },
  contact: {
    id: "contact",
    defaultMessage: "Contact",
  },
  experience: {
    id: "experience",
    defaultMessage: "Experience",
  },
  projects: {
    id: "projects",
    defaultMessage: "Projects",
  },
  resume: {
    id: "resume",
    defaultMessage: "Resume",
  },
  present: {
    id: "present",
    defaultMessage: "Present",
  },
  softwareEngineer: {
    id: "softwareEngineer",
    defaultMessage: "Software Engineer",
  },
  webDeveloper: {
    id: "webDeveloper",
    defaultMessage: "Web Developer",
  },
  carRental: {
    id: "carRental",
    defaultMessage: "Car Rental",
  },
  ecommerce: {
    id: "ecommerce",
    defaultMessage: "E-Commerce",
  },
  restaurant: {
    id: "restaurant",
    defaultMessage: "Restaurant",
  },
  realEstate: {
    id: "realEstate",
    defaultMessage: "Real Estate",
  },
  music: {
    id: "music",
    defaultMessage: "Music",
  },
  community: {
    id: "community",
    defaultMessage: "Community",
  },
  portfolio: {
    id: "portfolio",
    defaultMessage: "Portfolio",
  },
  game: {
    id: "game",
    defaultMessage: "Game",
  },
  english: {
    id: "english",
    defaultMessage: "English",
  },
  french: {
    id: "french",
    defaultMessage: "French",
  },
  spanish: {
    id: "spanish",
    defaultMessage: "Spanish",
  },
  creole: {
    id: "creole",
    defaultMessage: "Creole",
  },
  bio: {
    id: "bio",
    defaultMessage:
      "I craft seamless, visually striking digital experiences that captivate and connect with every user.",
  },
  aboutMeMessage: {
    id: "aboutMeMessage",
    defaultMessage:
      "I stumbled into coding as a tool to perform simple calculations, however I quickly got hooked, turning ideas into digital realities, one line of code at a time. From crafting engaging web projects to finding that perfect balance between creativity and logic, I thrive where design meets functionality. {br}{br} These days, I’m focused on building seamless user experiences for my employer (Kaseya). I also spend time learning new tricks to level up my skills. Whether it's for my employer, a startup, a nonprofit, or a local business, I make sure each project reflects the vision of my clients.{br}{br}Based in Miami, FL, I’m always open to new challenges and connections. When I'm not coding, you’ll find me practicng the Guitar or Ukulele, gaming, paticipating in sports or strength trainning.",
  },
  experience_1: {
    id: "experience_1",
    defaultMessage:
      "As a front-end developer on Datto's second-largest product, I played a key role in collaborating with product owners, designers, and developers to translate and create dozens of UI-based user stories into seamless user experiences using React and Node. I proactively identified, investigated, and resolved numerous customer-facing issues, including high-priority bugs, in partnership with the QA team. My contributions were recognized with an elevation to code reviewer/approver, where I ensured high standards of code quality and maintainability across the project. I also focused on enhancing test coverage by crafting and integrating tests to ensure robust and reliable code. Additionally, I authored and updated technical documentation, which streamlined the onboarding process for new developers and captured complex solutions not previously documented. My efforts to address and resolve technical debt paved the way for seamless library upgrades, ensuring the product’s technological relevance.",
  },
  experience_2: {
    id: "experience_2",
    defaultMessage:
      "successfully created and deployed more than 20 projects for various businesses, including e-commerce platforms, educational sites, investment portals, and personal websites. He has consistently implemented responsive user interfaces from Figma designs and PSDs using HTML, CSS, JavaScript, and associated frameworks and libraries like React and SCSS. His expertise includes redesigning existing applications to enhance user experience, integrating applications with APIs to manage emails, payments, and data reports, and developing robust content management systems with secure authentication to streamline efficiency and facilitate updates. Additionally, he has written security rules to protect databases from accidental or malicious threats and managed various aspects of web servers, including DNS records, emails, and FTP services.",
  },
  work_1: {
    id: "work_1",
    defaultMessage:
      "A portfolio website that can be reused by updated a config file with personal information.",
  },
  work_2: {
    id: "work_2",
    defaultMessage:
      "A car rental service that offers a wide range of vehicles for short-term and long-term rentals.",
  },
  work_3: {
    id: "work_3",
    defaultMessage:
      "An e-commerce platform specializing in trendy and customizable T-shirts and apparel.",
  },
  work_4: {
    id: "work_4",
    defaultMessage:
      "A vibrant Caribbean restaurant offering a taste of the islands with a variety of dishes.",
  },
  work_5: {
    id: "work_5",
    defaultMessage:
      "A company that specializes in housing and commercial properties throughout Miami-Dade County.",
  },
  work_6: {
    id: "work_6",
    defaultMessage:
      "A community-driven organization that brings people together through faith-based activities and events.",
  },
  work_7: {
    id: "work_7",
    defaultMessage:
      "Initial personal website with basic information about mytself and my work (archived).",
  },
  work_8: {
    id: "work_8",
    defaultMessage:
      "A musician and entertainer's portfolio and upcoming events based out of South Florida.",
  },
  work_9: {
    id: "work_9",
    defaultMessage:
      "Oware is a board game of African origin that has been passed down for generations.",
  },
});

export default messages;
